import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";
import head from "../images/head.png";

const MainHeader = () => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/app_settings/1/`
  );

  return (
    <>
      {data && (
        <div className="container d-flex flex-column max-vh-10 justify-content-center align-items-center">
          <Link to="/">
            {/* <img
              src={data.header_image}
              style={{ objectFit: "cover", width: "100%" }}
            /> */}
            <img src={head} style={{ objectFit: "cover", width: "100%" }} />
          </Link>
        </div>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "600px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <>{error}</>}
    </>
  );
};

export default MainHeader;
