import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";

const TimetableDatarow = ({ id, split }) => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/timeslots/?event=${id}`
  );

  return (
    <>
      <div className={`col-lg-${parseInt(12 / split)} artist-tag`}>
        {data && (
          <div className="col-md-12 d-lg-none mobile-date">
            {data[0].event_day}
          </div>
        )}
        {data &&
          data.map((artist, index) => (
            <span
              key={index}
              className={artist.artist_headliner ? "headliner" : "no-headliner"}
            >
              {!artist.artist_disable_link ? (
                <Link to={`/artist/${artist.artist_slug}/`}>
                  <span className="d-md-none mobile-time-tag">
                    {artist.start_time}
                  </span>
                  {artist.artist_name}
                  {artist.artist_headliner && ` (${artist.artist_country})`}
                </Link>
              ) : (
                <div className="nolink">
                  <span className="d-md-none mobile-time-tag">
                    {artist.start_time}
                  </span>
                  {artist.artist_name}
                  {artist.artist_headliner && ` (${artist.artist_country})`}
                </div>
              )}
            </span>
          ))}
        {isPending && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {error && <>{error}</>}
      </div>
    </>
  );
};
export default TimetableDatarow;
