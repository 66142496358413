import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";
import VenueTimetableDatarow from "./VenueTimetableDatarow";

const VenueTimetable = ({ id, name, address, first }) => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/events/?venue=${id}`
  );
  const [timeslots, setTimeslots] = useState([]);

  useEffect(() => {
    if (data) {
      let slots = [];
      data.forEach((e) => {
        e.timeslots.forEach((element) => {
          if (!slots.includes(element)) {
            slots.push(element);
          }
        });
      });
      setTimeslots(slots);
    }
  }, [data]);

  return (
    <div className="venue-timetable">
      {data && (
        <>
          {first && (
            <div className="row">
              <div className="col-1"></div>
              <div className="col-11 no-padding">
                <div className="container-fluid">
                  <div className="row" style={{ padding: "0 !important" }}>
                    <div className="col-3 artist-tag day-tag d-none d-lg-block">
                      <span>
                        <div className="tag">Július 24. szerda</div>
                      </span>
                    </div>
                    <div className="col-3 artist-tag day-tag d-none d-lg-block">
                      <span>
                        <div className="tag">Július 25. csütörtök</div>
                      </span>
                    </div>
                    <div className="col-3 artist-tag day-tag d-none d-lg-block">
                      <span>
                        <div className="tag">Július 26. péntek</div>
                      </span>
                    </div>
                    <div className="col-3 artist-tag day-tag d-none d-lg-block">
                      <span>
                        <div className="tag">Július 27. szombat</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-1 d-none d-md-block"></div>
            <div className="col-lg-11 col-md-12 venue-header d-flex align-items-center justify-content-center">
              <span>
                <b>{name}</b> ({address})
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-1 d-none d-lg-block">
              {timeslots &&
                timeslots.map((e, i) => (
                  <div className="time-tag" key={i}>
                    <span>
                      <div className="tag">{e.slice(0, -3)}</div>
                    </span>
                  </div>
                ))}
            </div>

            <div className="col-lg-11 col-md-12 col-xs-6 no-padding">
              <div className="container-fluid">
                <div className="row" style={{ padding: "0 !important" }}>
                  {data.map((event, index) => (
                    <VenueTimetableDatarow
                      key={index}
                      id={event.id}
                      split={data.length}
                    />
                  ))}
                </div>
              </div>
            </div>
            {isPending && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "30px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {error && <>{error}</>}
          </div>
        </>
      )}
    </div>
  );
};

export default VenueTimetable;
