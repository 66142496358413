import { Link } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";

const Headliners = () => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/artists/?headliner=1`
  );
  return (
    <>
      {data && (
        <div className="text-center headliners">
          {data.map((artist, i) => (
            <span key={i} className="headliner-artist">
              <b>
                <Link to={`/artist/${artist.slug}`}>{artist.name}</Link>
              </b>
              &nbsp;({artist.country})
            </span>
          ))}
          <span className="headliner-more">
            ... és az idei utcazenész verseny 20 döntőse
          </span>
        </div>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "600px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <span>{error}</span>}
    </>
  );
};

export default Headliners;
