import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";

const SponsorLogos = () => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/logos/`
  );
  return (
    <div className="row">
      {data && (
        <div className="d-flex flex-row flex-wrap">
          {data.map((e, i) => (
            <div className="p-2 logowrapper" key={i}>
              <img
                src={e.logo_image}
                style={{
                  objectFit: "contain",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </div>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <>{error}</>}
    </div>
  );
};

export default SponsorLogos;
