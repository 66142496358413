import { useParams } from "react-router-dom";

import LiteYoutubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import Spinner from "react-bootstrap/Spinner";

import PrivacyPolicy from "./PrivacyPolicy";
import MainHeader from "./MainHeader";
import ArtistVotingBlock from "./ArtistVotingBlock";
import useFetch from "../useFetch";
import ReactGA from "react-ga";

const Artist = () => {
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  const { id } = useParams();
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/artists/${id}/`
  );

  return (
    <>
      <div className="container col-12">
        <MainHeader />
        {data && (
          <>
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-xs-12"></div>
              <h1>
                <span style={{ fontWeight: 900 }}>{data.name}</span> (
                {data.country})
              </h1>
            </div>
            <div className="row artist-detail">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <p dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                {data.youtube_embed && (
                  <LiteYoutubeEmbed id={data.youtube_embed} title={data.name} />
                )}
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img
                  src={data.image}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h2>Itt és ekkor láthatod:</h2>
                {data.timeslots.map((e, i) => (
                  <span key={i} className="artist-timeslots">
                    {e.event__venue__name} - {e.event__start_time}{" "}
                    {e.start_time.slice(0, -3)} - {e.end_time.slice(0, -3)}
                  </span>
                ))}
              </div>
              {!data.headliner && (
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <ArtistVotingBlock artist={data.id} />
                </div>
              )}
            </div>
          </>
        )}
        {isPending && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {error && <p>{error}</p>}
      </div>
      <PrivacyPolicy />
    </>
  );
};

export default Artist;
