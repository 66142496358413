import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";
import { useNavigate } from "react-router-dom";

const ArtistGallery = ({ title = "Külföldi fellépők", urlFilter }) => {
  const navigate = useNavigate();
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/artists/${urlFilter}`
  );
  return (
    <>
      <h1 style={{ margin: "10vh 0 5vh 0" }}>{title}</h1>
      {data && (
        <div className="row">
          {data.map((artist, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-4 col-sm-6"
              style={{ padding: "0" }}
            >
              <div
                className="d-flex align-items-end gallery-elem"
                style={{
                  backgroundImage: `url(${artist.image})`,
                  backgroundSize: "cover",
                  height: "300px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/artist/${artist.slug}/`)}
              >
                <span>
                  <b>
                    <Link to={`/artist/${artist.slug}/`}>{artist.name}</Link>
                  </b>
                  {artist.country}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <span>{error}</span>}
    </>
  );
};

export default ArtistGallery;
