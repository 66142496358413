import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import useFetch from "../useFetch";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import MainHeader from "./MainHeader";
import PrivacyPolicy from "./PrivacyPolicy";

const Voter = ({ artist = false }) => {
  const [formData, updateFormData] = useState(artist ? { artist: artist } : {});
  const [isSent, setIsSent] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [message, setMessage] = useState();
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(
    process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY ? false : true
  );
  const { data } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/artists/?headliner=0`
  );
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.artist) {
      setMessage("Kérlek töltsd ki az email-címed és válassz fellépőt!");
      setConflict(true);
    }

    fetch(`https://${process.env.REACT_APP_SERVER_NAME}/api/vote/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.status === 200) {
        setIsSent(true);
        setConflict(false);
      } else if (response.status === 409) {
        setMessage("Ezzel az email címmel már rögzítettünk szavazatot!");
        setConflict(true);
      }
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onCaptchaChange = () => {
    setIsCaptchaSuccess(true);
  };

  return (
    <div className="container">
      {data && (
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ minHeight: artist ? "auto" : "80vh" }}
        >
          {!artist && (
            <div className="col-lg-7 col-md-12">
              <MainHeader />
            </div>
          )}

          <div
            className={`${
              artist
                ? "artist-voting-block col-12"
                : "voting col-lg-5 col-md-12"
            }`}
          >
            {!artist ? (
              <>
                <h1>Szavazás</h1>
                <p>Szavazz a kedvenc versenyződre!</p>
              </>
            ) : (
              <>
                <h3>
                  {data ? data.filter((e) => e.id === artist)[0].name : "Ő"} a
                  kedvenced?
                </h3>
                <p>Szavazz rájuk az email-címed megadásával!</p>
              </>
            )}

            {!isSent ? (
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Group className="mt-3" controlId="formBasicEmail">
                  <Form.Label>Email cím</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                  />
                </Form.Group>
                {!artist && (
                  <Form.Group className="mt-3" controlId="formArtist">
                    <Form.Label>Előadó</Form.Label>
                    <Form.Select onChange={handleChange} name="artist">
                      <option key="0" disabled="disabled" selected={true}>
                        Válassz előadót...
                      </option>
                      {data &&
                        data
                          .filter((e) => !e.disable_link)
                          .map((e, i) => (
                            <option key={i + 1} value={e.id}>
                              {e.name}
                            </option>
                          ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="loginButton"
                    disabled={isCaptchaSuccessful}
                  >
                    Szavazok!
                  </Button>
                </Form.Group>
                <Form.Group>
                  {conflict && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {message}
                    </span>
                  )}
                </Form.Group>
              </Form>
            ) : (
              <>
                <h3>A szavazatod rögzítésre került!</h3>
                <p>Köszönjük a szavazatod. Hamarosan eredményhirdetés!</p>
                <Link className="voting-block-link" to="/">
                  Vissza a főoldalra
                </Link>
              </>
            )}
            {process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY && (
              <>
                <ReCAPTCHA
                  onChange={onCaptchaChange}
                  size="invisible"
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
                  theme="light"
                />
              </>
            )}
          </div>
        </div>
      )}
      {!artist && <PrivacyPolicy />}
    </div>
  );
};

export default Voter;
