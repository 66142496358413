import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [cookies] = useCookies(["csrftoken"]);

  useEffect(() => {
    setIsPending(true);
    setData(null);

    if (url !== null) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw Error(
              `${response.status} - could not fetch data from resource`
            );
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          setIsPending(false);
          setError(null);
        })
        .catch((err) => {
          setIsPending(false);
          setError(err.message);
        });
    }
  }, [url, cookies]);

  return { data, isPending, error };
};

export default useFetch;
