import useFetch from "../useFetch";
import VenueTimetable from "./VenueTimetable";
import Spinner from "react-bootstrap/Spinner";

const Timetable = () => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/venues/`
  );
  return (
    <>
      <div>
        {data && (
          <>
            {data.map((venue, index) => (
              <VenueTimetable
                id={venue.id}
                key={index}
                name={venue.name}
                address={venue.address}
                first={index === 0}
              />
            ))}
          </>
        )}
        {isPending && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {error && <span>{error}</span>}
      </div>
    </>
  );
};

export default Timetable;
