import MainHeader from "./MainHeader";
import SponsorLogos from "./SponsorLogos";
import Headliners from "./Headliners";
import ArtistGallery from "./ArtistGallery";
import ArtistList from "./ArtistList";
import Timetable from "./Timetable";
import PrivacyPolicy from "./PrivacyPolicy";
import VotingBlock from "./VotingBlock";
import ReactGA from "react-ga";
import LiteYoutubeEmbed from "react-lite-youtube-embed";

const Content = () => {
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <>
      <div className="container">
        <MainHeader />
        <div className="row">
          <div
            className="offset-lg-2 offset-md-2 offset-sm-2 offset-xs-2 col-lg-8 col-md-8 col-sm-8 col-xs-8"
            style={{margin: "30px auto"}}
          >
            <LiteYoutubeEmbed
              id={"JtccJR0wBUk"}
              title={"Veszprémi Utcazene Fesztivál 2023 (Official Aftermovie)"}
            />
          </div>
        </div>
        <Headliners />
        <SponsorLogos />
        <VotingBlock />
        <Timetable />
        <div className="d-flex justify-content-center">
          <h3>Megvalósult a Nemzeti Kulturális Alap támogatásával</h3>
        </div>
        <ArtistGallery urlFilter="?headliner=1" />
        {/* <ArtistList title="Külföldi fellépők" urlFilter="?headliner=1" /> */}
        <ArtistList title="Utcazenészek 2024-ben" urlFilter="?headliner=0" />
        <div className="row" style={{ margin: "6vh 0" }}>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=12plW9qjTupsu26_lLGD-lnE4jqUczO4U"
            width="100%"
            height="600"
          ></iframe>
        </div>
        <PrivacyPolicy />
      </div>
    </>
  );
};

export default Content;
