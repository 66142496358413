import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";

const ArtistList = ({ title, urlFilter }) => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/artists/${urlFilter}`
  );
  return (
    <>
      <h1 className="artist-list-h">{title}</h1>
      {data && (
        <>
          {data.map((artist, index) => (
            <div key={index} className="container artist-list">
              <div className="row">
                <div className="col-12 d-flex flex-column min-vh-10 justify-content-center align-items-left">
                  <span style={{ marginLeft: "1vw" }}>
                    <b>
                      <Link to={`/artist/${artist.slug}/`}>{artist.name}</Link>
                    </b>
                    {artist.country}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <span>{error}</span>}
    </>
  );
};

export default ArtistList;
