import Spinner from "react-bootstrap/Spinner";
import useFetch from "../useFetch";
import Voter from "./Voter";

const ArtistVotingBlock = ({ artist }) => {
  const { data, isPending, error } = useFetch(
    `https://${process.env.REACT_APP_SERVER_NAME}/api/app_settings/1/`
  );

  return (
    <>
      {data && data.voting_enabled && (
        <div className="d-flex flex-column min-vh-10 justify-content-center align-items-center voting-block">
          <Voter artist={artist} />
        </div>
      )}
      {isPending && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "600px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && <>{error}</>}
    </>
  );
};

export default ArtistVotingBlock;
