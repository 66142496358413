import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import Content from "./components/Content.js";
// import ContentPromo from "./components/ContentPromo.js";
import Content from "./components/Content.js";
import Artist from "./components/Artist.js";
import Voter from "./components/Voter.js";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/artist/:id" element={<Artist />} />
          <Route path="/vote" element={<Voter />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
